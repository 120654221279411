<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="quotations" v-model:filters="filters1" v-model:selection="selectedRow"
                    selectionMode="multiple" :metaKeySelection="false" :filters="filters1" filterDisplay="menu"
                    dataKey="id" :globalFilterFields="['quotno']" scrollable :tableStyle="styleHeight"
                    :scrollHeight="sHeight" responsiveLayout="scroll" :loading="loading" :paginator="true" :lazy="true"
                    :rows="pagesize" :virtualScrollerOptions="vsOptionFun()" :rowsPerPageOptions="[50, 100, 200, 500]"
                    :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:30%">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="输入报价单号或对方公司模糊查询"
                                    style="width:100%" @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;"></Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :showFilterMenu="col.showFilterMenu" :showFilterMatchModes="col.showFilterMenu"
                        :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink || col.oneline || col.isimage" #body="slotProps">
                            <Button v-if="col.islink" :label="slotProps.data.quotno"
                                @click="openQuotation(slotProps.data)" class="p-button-link" />
                            <label v-if="col.oneline" class="oneline"
                                v-tooltip.top="{ value: slotProps.data[col.field], disabled: false }">{{slotProps.data[col.field]}}</label>
                            <Image v-if="col.isimage" :src="getFilePatch(slotProps.data[col.field])" width="50"
                                preview />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading"
                :saveBeforeMethod="saveBefore" :currentRow="currentRow" :childNewDataConfig="childNewDataConfig"
                :getChildTableColWidthMehod="getChildTableColWidth" :childDelDataConfig="childDelDataConfig"
                :closeParentMehod="closeMaximizable" :freshListMethod="freshList"
                :CellEditCompleteMethod="cellEditComplete" :dropOtherConfig="dropOtherConfig"
                :importAddQueryConditionMethod="importAddQueryCondition" :loadDataAfterMethod="loadDataAfter"
                :childTableFooter="childTableFooter" :pageJsonConfig="pageJsonConfig"
                :childExpandePageConfig="childExpandeConfig" :CellEditInit="productCellEditInit"
                :CellEditCompleteExpandeMethod="cellEditCompleteExpande" :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button :label="currentState" icon="pi pi-sitemap" class="p-button-raised p-button-text mr-2"
                    @click="openLeftPosition" />
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="保存" icon="pi pi-check" @click="saveData" :loading="butLoading"
                    autofocus />
                <Button v-if="!readOnly" label="提交" icon="pi pi-check" @click="submitData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="流程信息" v-model:visible="displayPosition" :style="{width: '50vw'}" :position="position"
        :maximizable="true" :modal="true">
        <FlowHistory ref="flowhistory" :flowHistoryData="flowhistoryJson" :title="recordsubject" />
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closePosition" autofocus />
        </template>
    </Dialog>
    <Dialog header="历史报价单" v-model:visible="showHistoryDialog" :style="{width: '70vw'}" :maximizable="true"
        :modal="true">
        <p class="p-m-0">
            <DataTable :value="quotationshistory" v-model:selection="selectedHistoryRow" :metaKeySelection="false"
                filterDisplay="menu" dataKey="id" selectionMode="single" scrollable scrollHeight="350px"
                responsiveLayout="scroll" :loading="loading" :paginator="true" rows="50"
                :totalRecords="totalHistoryRecords" :first="firstHistory"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                @page="listHistoryPage($event)">
                <template #header>
                    <div class="flex justify-content-between flex-column sm:flex-row">
                    </div>
                </template>
                <Column selectionMode="single" frozen bodyStyle="width:3em;flex:0;" headerStyle="width:3em;flex:0;">
                </Column>
                <Column v-for="col of historycolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                    :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                    :key="col.field" :showFilterMenu="col.showFilterMenu" :showFilterMatchModes="col.showFilterMenu"
                    :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable" :frozen="col.frozen">
                    <template v-if="col.oneline || col.isimage" #body="slotProps">
                        <label v-if="col.oneline" class="oneline"
                            v-tooltip.top="{ value: slotProps.data[col.field], disabled: false }">{{slotProps.data[col.field]}}</label>
                        <Image v-if="col.isimage" :src="getFilePatch(slotProps.data[col.field])" width="50" preview />
                    </template>
                    <template #filter="{filterModel}">
                        <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                    </template>
                    <template #filterclear="{filterCallback}">
                        <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                    </template>
                    <template #filterapply="{filterCallback}">
                        <Button type="button" label="查询" @click="filterCallback();customFilter();"
                            class="p-button-success"></Button>
                    </template>
                </Column>
            </DataTable>
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeQuotationData" />
                <Button label="导入" icon="pi pi-check" @click="importQuotationData" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
</template>
<style type="text/css">
    .oneline {
        width: 160px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import {
        FilterMatchMode,
    } from 'primevue/api';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import ConfigService from '../../../service/ConfigService';
    import CommonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import FlowHistory from '../../../components/FlowHistory.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    // import quotationgePageJson from "@/data/quotationConfig.json";
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '744';
            const listcolumns = ref();
            const historycolumns = ref();
            const usermoduleauth = [];
            return {
                confirm,
                currentMenuId,
                listcolumns,
                historycolumns,
                usermoduleauth
            };
        },
        data() {
            return {
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                pageJsonConfig: {},
                butLoading: false,
                recordsubject: '',
                readOnly: false,
                displayMaximizable: false,
                displayQueryPlan: false,
                displayPosition: false,
                showHistoryDialog: false,
                position: 'bottomleft',
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                flowId: '',
                startStateId: '',
                currentState: '流程信息-当前环节:报价申请',
                currentRow: {
                    bindpage: 'quotation',
                    id: '-1',
                    workflowid: '',
                    flowguid: 'Quotation',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                flowhistoryJson: null,
                selectedRow: ref(),
                selectedHistoryRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                totalHistoryRecords: 0,
                firstHistory: 0,
                listTitle: '草稿列表',
                selectedKey: {
                    744: true
                },
                childNewDataConfig: {
                    products: false,
                },
                childDelDataConfig: {
                    products: true
                },
                childTableFooter: {
                    products: {
                        url: '合计',
                        loadingqty: 0,
                        price: 0,
                        grossweight: 0,
                        netweight: 0
                    }
                },
                expandedKey: null,
                currencyDict: [],
                childExpandeConfig: {},
                quotations: [],
                quotationshistory: [],
                loading: true,
                nodes: [],
                items: [{
                    label: '报价操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                            label: '新建报价',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.createQuotation();
                            }
                        },
                        {
                            label: '导入历史',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.createQuotationFromHistory();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '删除',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                this.delQuotation();
                            }
                        }
                    ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
                dropOtherConfig: {
                    factoryid: 'address',
                    factoryidfun: this.factorychange,
                    currency: 'exchangerate',
                    currencyfun: (childelements, v_val, v_dictsource) => {
                        this.currencyChange(childelements, v_val, v_dictsource);
                    },
                },
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.configService.getquotation(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                        'childExpandeConfig'
                    ];
                    this.initHistoryColumn();
                    this.initFilters1();
                    this.loadMenuTree();
                    this.loadFlowInfo();
                    this.loadUserAuth();
                    CommonJs.loadDictFromCache('Currency', 'dictionaryCurrency', this.currencyDict, this
                        .setloadDictFromCache);
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            setloadDictFromCache(v_currencyDict) {
                this.currencyDict = v_currencyDict;
            },
            initHistoryColumn() {
                this.historycolumns = [{
                    field: 'quotno',
                    header: '报价单号',
                    bodyStyle: 'min-width:160px;left:0px',
                    headerStyle: 'min-width:160px;left:0px',
                    showFilterOperator: false,
                    filterMatchModeOptions: this.matchModes,
                    frozen: true,
                    sortable: true,
                    showFilterMenu: false,
                    islink: false,
                }, {
                    field: 'companyname',
                    header: '工厂',
                    bodyStyle: 'min-width:180px',
                    headerStyle: 'min-width:180px',
                    showFilterOperator: false,
                    filterMatchModeOptions: this.matchModes,
                    frozen: false,
                    sortable: true,
                    showFilterMenu: false,
                    oneline: true,
                }, {
                    field: 'address',
                    header: '地址',
                    bodyStyle: 'min-width:170px',
                    headerStyle: 'min-width:170px',
                    showFilterOperator: false,
                    filterMatchModeOptions: this.matchModes,
                    frozen: false,
                    sortable: true,
                    showFilterMenu: false,
                    oneline: true,
                }, {
                    field: 'quotationdate',
                    header: '报价日期',
                    bodyStyle: 'min-width:160px',
                    headerStyle: 'min-width:160px',
                    showFilterOperator: false,
                    filterMatchModeOptions: this.timeMatchModes,
                    frozen: false,
                    sortable: true,
                    showFilterMenu: false,
                    istime: true,
                }, {
                    field: 'paymenttermname',
                    header: '付款方式',
                    bodyStyle: 'min-width:180px',
                    headerStyle: 'min-width:180px',
                    showFilterOperator: false,
                    filterMatchModeOptions: this.matchModes,
                    frozen: false,
                    sortable: true,
                    showFilterMenu: false,
                }, {
                    field: 'moq',
                    header: '最小订货量',
                    bodyStyle: 'min-width:160px',
                    headerStyle: 'min-width:160px',
                    showFilterOperator: false,
                    filterMatchModeOptions: this.matchModes,
                    frozen: false,
                    sortable: true,
                    showFilterMenu: false,
                }, {
                    field: 'deliverytime',
                    header: '交货时间',
                    bodyStyle: 'min-width:170px',
                    headerStyle: 'min-width:170px',
                    showFilterOperator: false,
                    filterMatchModeOptions: this.timeMatchModes,
                    frozen: false,
                    sortable: true,
                    showFilterMenu: false,
                    istime: true,
                }];
            },
            initFilters1() {
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('741', this.currentMenuId, this.$route, this
                    .loadData, this.setlistTitleFun, this.setloadMenuTree);
            },
            loadUserAuth() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '741',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usermoduleauthlist(where:" + JSON.stringify(listwhere) +
                    "){appid own authtype}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.usermoduleauth = jsonData.data.usermoduleauthlist;

                        var createquotationflag = this.$route.query['createquotation'];
                        if (createquotationflag) {
                            this.createQuotation();
                        }
                    }
                });
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '草稿列表', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            listHistoryPage(event) {
                var page = event.page;
                this.loadHistoryData(page + 1);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'draftbox',
                        value: '',
                        operation: '='
                    }]
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'quotno,companyname',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{quotationlist(where:" + JSON.stringify(listwhere) +
                    "){id companyname quotno address quotationdate linkone emailone mobileone faxone linktwo emailtwo mobiletwo faxtwo paymentterm paymenttermname moq deliverytime tradeterms approvestatus approvestatusname flowid workflowid boxid stateid currentstatename}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.quotations = jsonData.data.quotationlist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                    }
                });
            },
            loadHistoryData(v_page) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: [{
                        name: 'importdata',
                        value: '',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{quotationlist(where:" + JSON.stringify(listwhere) +
                    "){id companyname factoryid quotno address quotationdate linkone emailone mobileone faxone linktwo emailtwo mobiletwo faxtwo paymentterm paymenttermname moq deliverytime tradeterms products {boxnumber color colorname height length packingnumber packingway producttype size width url productname mpn sku price costprice description package grossweight netweight packingsize loadingqty unitname remark unit productid parentid  parts {id creater createtime mainid parentid productid url productname mpn sku description package grossweight netweight packingsize unit unitname price remark matching loadingqty productpartsid costprice boxnumber color colorname packingnumber length height width packingway size producttype} id createrid creater createtime  isdel ischeck}}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.quotationshistory = jsonData.data.quotationlist;
                        this.totalHistoryRecords = jsonData.page.allrecord;
                        this.firstHistory = 50 * (jsonData.page.pageindex - 1);
                    }
                });
            },
            createQuotation() {
                if (!CommonJs.userOwerAppId(this.usermoduleauth, 'CreateQuotation')) {
                    MessageTip.warnmsg('请联系管理员开通新建报价权限');
                    return;
                }
                this.recordsubject = '新建报价';
                this.childTableFooter = {
                    products: {
                        url: '合计',
                        loadingqty: 0,
                        price: 0,
                        grossweight: 0,
                        netweight: 0
                    }
                };
                this.selectedHistoryRow = null;
                this.currentRow = {
                    bindpage: 'quotation',
                    id: '-1',
                    workflowid: '',
                    flowguid: 'Quotation',
                    boxid: '',
                    stateid: this.startStateId,
                    flowid: this.flowId,
                };
                this.displayMaximizable = true;
            },
            createQuotationFromHistory() {
                if (!CommonJs.userOwerAppId(this.usermoduleauth, 'CreateQuotation')) {
                    MessageTip.warnmsg('请联系管理员开通新建报价权限');
                    return;
                }
                this.recordsubject = '新建报价';
                this.childTableFooter = {
                    products: {
                        url: '合计',
                        loadingqty: 0,
                        price: 0,
                        grossweight: 0,
                        netweight: 0
                    }
                };
                this.selectedHistoryRow = null;
                this.loadHistoryData();
                this.showHistoryDialog = true;
            },
            openQuotation(v_data) {
                this.currentState = '流程信息-当前环节:' + v_data.currentstatename;
                this.recordsubject = '报价单' + v_data.quotno;
                this.childTableFooter = {
                    products: {
                        url: '合计',
                        loadingqty: 0,
                        price: 0,
                        grossweight: 0,
                        netweight: 0
                    }
                };
                this.currentRow = {
                    bindpage: 'quotation',
                    id: v_data.id,
                    workflowid: v_data.workflowid,
                    flowguid: 'Quotation',
                    boxid: v_data.boxid,
                    stateid: this.startStateId,
                    flowid: this.flowId,
                };
                this.selectedHistoryRow = null;
                this.displayMaximizable = true;
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            closeQuotationData() {
                this.showHistoryDialog = false;
            },
            importQuotationData() {
                if (!this.selectedHistoryRow) {
                    MessageTip.warnmsg('请选择要导入的报价单');
                    return;
                }
                this.currentRow = {
                    bindpage: 'quotation',
                    id: '-1',
                    workflowid: '',
                    flowguid: 'Quotation',
                    boxid: '',
                    stateid: this.startStateId,
                    flowid: this.flowId,
                };
                this.showHistoryDialog = false;
                this.displayMaximizable = true;
            },
            delQuotation() {
                this.$confirm.require({
                    message: '确认要删除?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        if (!this.selectedRow || this.selectedRow.length == 0) {
                            MessageTip.warnmsg('请选择要删除报价单');
                            return;
                        }
                        var quotationIds = [];
                        for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                            quotationIds.push({
                                "id": this.selectedRow[k1]["id"]
                            });
                        }
                        requests.graphql_requests.post(
                            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                            "delete{quotationlist(o:" + JSON.stringify(quotationIds) +
                            "){id errmsg}}"
                        ).then(res => {
                            if (res.errcode == "0") {
                                this.selectedRow = null;
                                this.loadData(1);
                                MessageTip.successmsg('删除成功');
                            } else {
                                MessageTip.warnmsg('删除失败');
                            }
                        });
                    },
                    reject: () => {}
                });
            },
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            saveBefore(v_data) {
                console.log('saveBefore:');
                console.log(v_data);
                v_data['approvestatus'] = '0';
                v_data['appresult'] = {};
                v_data['shyj'] = {};
                return v_data;
            },
            submitData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo();
            },
            saveData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo(true);
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            openLeftPosition() {
                console.log('recordsubject:' + this.recordsubject);
                this.flowhistoryJson = this.$refs.universalpage.flowhistory;
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            productCellEditInit(event, tablename, colconfigs) {
                if (tablename == 'products') {
                    var countindex = 0;
                    for (var i = 0; i < colconfigs.length; i++) {
                        if (colconfigs[i]['field'] == 'price') {
                            if (event.data['producttype'] && event.data['producttype'] == '2') {
                                colconfigs[i]['type'] = 'LabelString';
                            } else {
                                colconfigs[i]['type'] = 'Decimal';
                            }
                            countindex = countindex + 1;
                        }
                        if (colconfigs[i]['field'] == 'grossweight') {
                            if (event.data['producttype'] && event.data['producttype'] == '2') {
                                colconfigs[i]['type'] = 'LabelString';
                            } else {
                                colconfigs[i]['type'] = 'Decimal';
                            }
                            countindex = countindex + 1;
                        }
                        if (colconfigs[i]['field'] == 'netweight') {
                            if (event.data['producttype'] && event.data['producttype'] == '2') {
                                colconfigs[i]['type'] = 'LabelString';
                            } else {
                                colconfigs[i]['type'] = 'Decimal';
                            }
                            countindex = countindex + 1;
                        }
                        if (countindex >= 3) {
                            break;
                        }
                    }
                }
            },
            cellEditComplete(tablename, field, data, rowdata, childTableFooterObj) {
                console.log('cellEditCompleteMethod..');
                console.log(tablename);
                console.log(field);
                console.log(data);
                console.log(rowdata);
                console.log(childTableFooterObj);
                if (tablename == 'products' && field == '' && rowdata['parts']) {
                    var pricenum2 = 0;
                    var costpricenum2 = 0;
                    var netweight2 = 0;
                    var grossweight2 = 0;
                    for (var i2 = 0; i2 < rowdata['parts'].length; i2++) {
                        var matchingnum3 = Number(rowdata['parts'][i2]['matching']);
                        var pricenum3 = Number(rowdata['parts'][i2]['price']);
                        var costpricenum3 = Number(rowdata['parts'][i2]['costprice']);
                        var netweight3 = Number(rowdata['parts'][i2]['netweight']);
                        var grossweight3 = Number(rowdata['parts'][i2]['grossweight']);
                        if (!isNaN(pricenum3) && !isNaN(matchingnum3)) {
                            pricenum2 = pricenum2 + pricenum3 * matchingnum3;
                        }
                        if (!isNaN(costpricenum3) && !isNaN(matchingnum3)) {
                            costpricenum2 = costpricenum2 + costpricenum3 * matchingnum3;
                        }
                        if (!isNaN(netweight3) && !isNaN(matchingnum3)) {
                            netweight2 = netweight2 + netweight3 * matchingnum3;
                        }
                        if (!isNaN(grossweight3) && !isNaN(matchingnum3)) {
                            grossweight2 = grossweight2 + grossweight3 * matchingnum3;
                        }
                    }
                    if (rowdata['parts'].length > 0) {
                        rowdata['price'] = pricenum2;
                        rowdata['costprice'] = costpricenum2;
                        rowdata['netweight'] = netweight2;
                        rowdata['grossweight'] = grossweight2;
                    }
                }
            },
            cellEditCompleteExpande(tablename, field, currentrow, parentRow, allcurrentdata, allparentdata,
                childTableFooterObj) {
                console.log('cellEditCompleteExpandeMethod..');
                console.log(tablename);
                console.log(field);
                console.log(currentrow);
                console.log(parentRow);
                console.log(allcurrentdata);
                console.log(allparentdata);
                console.log(childTableFooterObj);
                var price = 0;
                var costprice = 0;
                var grossWeight = 0;
                var netWeight = 0;
                for (var i = 0; i < allcurrentdata.length; i++) {
                    if (!isNaN(Number(allcurrentdata[i]['price'])) && !isNaN(Number(allcurrentdata[i]['matching']))) {
                        price = price + Number(allcurrentdata[i]['price']) * Number(allcurrentdata[i]['matching']);
                    }
                    if (!isNaN(Number(allcurrentdata[i]['costprice'])) && !isNaN(Number(allcurrentdata[i][
                            'matching'
                        ]))) {
                        costprice = costprice + Number(allcurrentdata[i]['costprice']) * Number(allcurrentdata[i][
                            'matching'
                        ]);
                    }
                    if (!isNaN(Number(allcurrentdata[i]['grossweight'])) && !isNaN(Number(allcurrentdata[i][
                            'matching'
                        ]))) {
                        grossWeight = grossWeight + Number(allcurrentdata[i]['grossweight']) * Number(allcurrentdata[i][
                            'matching'
                        ]);
                    }
                    if (!isNaN(Number(allcurrentdata[i]['netweight'])) && !isNaN(Number(allcurrentdata[i][
                            'matching'
                        ]))) {
                        netWeight = netWeight + Number(allcurrentdata[i]['netweight']) * Number(allcurrentdata[i][
                            'matching'
                        ]);
                    }
                }
                parentRow['price'] = price;
                parentRow['costprice'] = costprice;
                parentRow['grossweight'] = grossWeight;
                parentRow['netweight'] = netWeight;
                price = 0;
                costprice = 0;
                grossWeight = 0;
                netWeight = 0;
                for (var i2 = 0; i2 < allparentdata.length; i2++) {
                    if (allparentdata[i2]['id'] == parentRow['id']) {
                        allparentdata[i2]['price'] = parentRow['price'];
                        allparentdata[i2]['costprice'] = parentRow['costprice'];
                        allparentdata[i2]['grossweight'] = parentRow['grossweight'];
                        allparentdata[i2]['netweight'] = parentRow['netweight'];
                    }
                    if (!isNaN(Number(allparentdata[i2]['price']))) {
                        price = price + Number(allparentdata[i2]['price']);
                    }
                    if (!isNaN(Number(allparentdata[i2]['costprice']))) {
                        costprice = costprice + Number(allparentdata[i2]['costprice']);
                    }
                    if (!isNaN(Number(allparentdata[i2]['grossweight']))) {
                        grossWeight = grossWeight + Number(allparentdata[i2]['grossweight']);
                    }
                    if (!isNaN(Number(allparentdata[i2]['netweight']))) {
                        netWeight = netWeight + Number(allparentdata[i2]['netweight']);
                    }
                }
                //childTableFooterObj['products']['price'] = price;
                // childTableFooterObj['products']['costprice'] = costprice;
                // childTableFooterObj['products']['grossweight'] = grossWeight;
                // childTableFooterObj['products']['netweight'] = netWeight;
            },
            importAddQueryCondition(v_listwhere, v_moduleName) {
                console.log(v_moduleName);
                v_listwhere.conditions.push({
                    name: 'status',
                    value: '0',
                    operation: '=',
                });
                if (v_moduleName == 'product') {
                    var allmainelements = this.$refs.universalpage.getAllMainElements();
                    console.log('importAddQueryCondition...');
                    console.log(allmainelements);
                    var factoryid = '';
                    for (var k = 0; k < allmainelements.length; k++) {
                        if (allmainelements[k]['name'] == 'factoryid') {
                            factoryid = allmainelements[k]['val'];
                            break;
                        }
                    }
                    if (factoryid == '' || factoryid == '-1') {
                        MessageTip.warnmsg('请先选择工厂');
                        v_listwhere.conditions.push({
                            name: 'err',
                            value: '',
                            operation: '',
                        });
                    } else {
                        v_listwhere.conditions.push({
                            name: 'factoryid',
                            value: factoryid,
                            operation: '=',
                        });
                    }
                    return v_listwhere;
                }
                return v_listwhere;
            },
            loadFlowInfo() {
                requests.plat_requests.post(
                    '/calf/plat/FlowInfo?appkey=' + sessionStorage.getItem(
                        "appkey"), {
                        FlowGuid: 'Quotation',
                        BoxId: '',
                    }).then(res => {
                    console.log('loadFlowInfo...');
                    console.log(res);
                    if (res.errcode == "0") {
                        var retObj = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log(retObj);
                        this.flowId = retObj.FlowId;
                        this.startStateId = retObj.CurrentStateId;
                    }
                });
            },
            loadDataAfter(v_mainelements, v_childelements, v_originalData, v_noshowelements, v_childExpandeConfig) {
                console.log('loadDataAfter...');
                console.log(v_mainelements);
                console.log(v_childelements);
                console.log(v_originalData);
                console.log(v_noshowelements);
                console.log(this.selectedHistoryRow);
                if (this.currentRow && this.currentRow['id'] == '-1') {
                    if (this.selectedHistoryRow) {
                        for (var k1 = 0; k1 < v_mainelements.length; k1++) {
                            if (v_mainelements[k1]['name'] != 'id' && v_mainelements[k1]['name'] != 'quotno') {
                                if (v_mainelements[k1]['name'] == 'importno') {
                                    v_mainelements[k1]['val'] = this.selectedHistoryRow['quotno'];
                                } else {
                                    for (var pro in this.selectedHistoryRow) {
                                        if (v_mainelements[k1]['name'] == pro) {
                                            v_mainelements[k1]['val'] = this.selectedHistoryRow[pro];
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                        for (var originalpro in v_originalData.quotation) {
                            if (originalpro != 'id' && originalpro != 'quotno') {
                                if (originalpro == 'products') {
                                    v_originalData.quotation['products'] = [];
                                } else {
                                    if (originalpro == 'importno') {
                                        v_originalData.quotation['importno'] = this.selectedHistoryRow['quotno'];
                                        v_originalData.quotation['importid'] = this.selectedHistoryRow['id'];
                                        v_noshowelements['importid'] = this.selectedHistoryRow['id'];
                                        v_noshowelements['companyname'] = this.selectedHistoryRow['companyname'];
                                    } else {
                                        for (var pro2 in this.selectedHistoryRow) {
                                            if (originalpro == pro2) {
                                                v_originalData.quotation[originalpro] = this.selectedHistoryRow[pro2];
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        for (var k3 = 0; k3 < v_childelements.length; k3++) {
                            if (v_childelements[k3]['name'] == 'products') {
                                v_childelements[k3]['rows'] = [];
                                for (var k4 = 0; k4 < this.selectedHistoryRow['products'].length; k4++) {
                                    this.selectedHistoryRow['products'][k4]['id'] = this.$refs.universalpage
                                        .getNewId();
                                    this.selectedHistoryRow['products'][k4]['parentid'] = '-1';
                                    for (var k5 = 0; k5 < this.selectedHistoryRow['products'][k4]['parts']
                                        .length; k5++) {
                                        this.selectedHistoryRow['products'][k4]['parts'][k5]['id'] = this.$refs
                                            .universalpage
                                            .getNewId();
                                        this.selectedHistoryRow['products'][k4]['parts'][k5]['parentid'] = '-1';
                                    }
                                    v_childelements[k3]['rows'].push(this.selectedHistoryRow['products'][k4]);
                                    v_originalData.quotation['products'].push(this.selectedHistoryRow['products'][k4]);
                                }
                                break;
                            }
                        }
                        console.log(v_childelements);
                    } else {
                        for (var k2 = 0; k2 < v_childelements.length; k2++) {
                            if (v_childelements[k2]['name'] == 'products') {
                                v_childelements[k2]['rows'] = [];
                            }
                        }
                    }
                } else {
                    for (var k = 0; k < v_mainelements.length; k++) {
                        if (v_mainelements[k]['name'] == 'currency') {
                            this.currencyChange(v_childelements, v_mainelements[k]['val'], this.currencyDict,
                                v_childExpandeConfig);
                            break;
                        }
                    }
                }
            },
            currencyChange(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
                var ename = '';
                for (var i3 = 0; i3 < v_dictsource.length; i3++) {
                    if (v_dictsource[i3].code == v_val) {
                        ename = v_dictsource[i3].ename;
                        break;
                    }
                }
                var loopcnt = 0;
                var isbreak = false;
                for (var i = 0; i < v_childelements.length; i++) {
                    for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                        if (v_childelements[i].columns[i2].field == 'price') {
                            v_childelements[i].columns[i2].header = '价格(' + ename + ')';
                            loopcnt++;
                        }
                        if (loopcnt == 1) {
                            isbreak = true;
                            break;
                        }
                    }
                    if (isbreak) {
                        break;
                    }
                }
                if (v_childExpandeConfig) {
                    for (var i4 = 0; i4 < v_childExpandeConfig.products.config[0].columns.length; i4++) {
                        if (v_childExpandeConfig.products.config[0].columns[i4].field == 'price') {
                            v_childExpandeConfig.products.config[0].columns[i4].header = '价格(' + ename + ')';
                        }
                    }
                } else {
                    for (var i5 = 0; i5 < this.childExpandeConfig.products.config[0].columns.length; i5++) {
                        if (this.childExpandeConfig.products.config[0].columns[i5].field == 'price') {
                            this.childExpandeConfig.products.config[0].columns[i5].header = '价格(' + ename + ')';
                        }
                    }
                    this.$refs.universalpage.setchildExpandeConfig(this.childExpandeConfig);
                }
            },
            getChildTableColWidth(v_childname, v_fieldname) {
                if (v_childname == 'products' && v_fieldname == 'description') {
                    return 'width:200px;';
                } else {
                    return 'width:150px;';
                }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            factorychange(v_allchildelements, v_value, v_dictsource) {
                console.log('factorychange...');
                console.log(v_allchildelements);
                var linkone = '';
                var mobileone = '';
                for (var i = 0; i < v_dictsource.length; i++) {
                    if (v_dictsource[i]['code'] == v_value) {
                        for (var i2 = 0; i2 < v_dictsource[i]['child'].length; i2++) {
                            linkone = v_dictsource[i]['child'][i2]['name'];
                            mobileone = v_dictsource[i]['child'][i2]['other'];
                        }
                        break;
                    }
                }
                this.$refs.universalpage.modifyElementsVal('linkone', linkone);
                this.$refs.universalpage.modifyElementsVal('mobileone', mobileone);
            },
            dialogClose() {
                this.butLoading = false;
            }
        },
        components: {
            NewUniversalPage,
            FlowHistory,
            'AppFooter': AppFooter,
        }
    }
</script>